import Rails from "@rails/ujs"
import { createApp } from 'vue'
// import money from 'v-money3'
//
// import eventForm from '@/components/event_form/event_form.vue'
import registration from '@/components/registration/registration.vue'
//
document.addEventListener('DOMContentLoaded', () => {
    const reg_form = document.getElementById('v-reg-form');
    if (reg_form) {
        const app = createApp(registration,{});
        // app.use(money)
        app.mount(reg_form);
    }
})
Rails.start()

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById("event_location_id")
  if (el) {
    document.getElementById("event_location_id").onchange = function(){
      const limit_input = document.getElementById('event_players_limit')
      const option_limit = this.options[this.options.selectedIndex].dataset.limit
      document.getElementById('event_city_id').value = this.options[this.options.selectedIndex].parentNode.dataset.id
      if (option_limit > 0) {
        limit_input.value = option_limit
      }
    };
  }

    const menu_toggler = document.querySelectorAll('.menu-toggler')
    if (menu_toggler) {
        let sidebar = document.getElementById('sidebar');
        menu_toggler.forEach(el => {
            el.addEventListener('click', ()=>{
                if (sidebar.classList.contains('opened')) {
                    sidebar.classList.remove('opened')
                } else {
                    sidebar.classList.add('opened')
                }
            })
        })
    }
})